import { CSSProperties } from '@mui/material/styles/createTypography'

export interface TypographyStylesTypes {
  displayLarge: CSSProperties
  headlineLarge: CSSProperties
  headlineMedium: CSSProperties
  headlineSmall: CSSProperties
  titleMedium: CSSProperties
  labelLarge: CSSProperties
  labelMedium: CSSProperties
  labelSmall: CSSProperties
  bodyLarge: CSSProperties
  bodyMedium: CSSProperties
  bodySmall: CSSProperties
  body: CSSProperties
  titleSmall: CSSProperties
  title: CSSProperties
  subtitle1: CSSProperties
  subtitle2: CSSProperties
  heading: CSSProperties
  subheading: CSSProperties
  caption: CSSProperties
  label: CSSProperties
}
export const TypographyStyles: TypographyStylesTypes = {
  displayLarge: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '57px',
    lineHeight: '64px',
  },
  headlineLarge: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '32px',
    lineHeight: '32px',
  },
  heading: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '36px',
  },
  subheading: {
    fontSize: '18px',
    lineHeight: 'auto',
  },
  headlineMedium: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '28px',
    lineHeight: '32px',
  },
  headlineSmall: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '32px',
  },
  titleMedium: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
  },

  labelLarge: {
    fontStyle: 'medium',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
  },
  labelMedium: {
    fontStyle: 'bold',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
  },
  titleSmall: {
    fontStyle: 'medium',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
  },
  title: {
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: 'auto',
  },
  subtitle1: {
    fontSize: '18px',
    lineHeight: 'auto',
  },
  subtitle2: {
    fontSize: '16px',
    fontWeight: 500,
  },
  labelSmall: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '11px',
    lineHeight: '16px',
  },
  bodyLarge: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  },
  bodyMedium: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
  },
  bodySmall: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: ' 12px',
    lineHeight: '16px',
  },
  body: {
    fontSize: '14px',
    lineHeight: '16px',
  },
  caption: {
    fontStyle: 'normal',
    fontSize: '11px',
    lineHeight: '14px',
  },
  label: {
    fontStyle: 'normal',
    fontSize: '12px',
  },
}
