import { CSSInterpolation } from '@mui/material'

import colors from '../colors.json'
interface ButtonStyles {
  styleOverrides: {
    root: CSSInterpolation
  }
  variants: Array<{
    props: object
    style: CSSInterpolation
  }>
}

export const buttonStyles: ButtonStyles = {
  styleOverrides: {
    root: {
      borderRadius: '8px',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
    },
  },
  variants: [
    {
      props: { size: 'medium' },
      style: {
        padding: '12px 24px',
        fontSize: '14px',
        fontWeight: 700,
      },
    },
    {
      props: { size: 'small' },
      style: {
        padding: '8px 16px',
        fontSize: '12px',
        fontWeight: 700,
      },
    },
    {
      props: { variant: 'filled' },
      style: {
        backgroundColor: 'white',
        outline: '1px solid #E0E0E0',
      },
    },
    {
      props: { variant: 'contained', color: 'primary' },
      style: {
        color: 'white',
        fontWeight: 700,
      },
    },
    {
      props: { variant: 'contained', color: 'secondary' },
      style: {
        backgroundColor: colors.primary[50],
        color: colors.primary[600] as string,
        '&:hover': {
          backgroundColor: colors.primary[100],
        },
      },
    },
  ],
}
