import { createTheme } from '@mui/material'

import colors from './colors.json'
import { buttonStyles } from './styles/buttonStyles'
import { TypographyStyles } from './styles/TypographyStyles'

const customFontFamily = "'Inter'"

export const theme = createTheme({
  typography: {
    fontFamily: customFontFamily,
    h2: {
      fontSize: '3rem',
      marginBottom: '1rem',
    },
    h6: {
      fontSize: '1.1rem',
      marginBottom: '0rem',
    },
    button: {
      textTransform: 'none',
      fontWeight: 500,
    },
    ...TypographyStyles,
  },
  palette: {
    primary: colors.primary,
    secondary: colors.secondary,
    tertiary: colors.tertiary,
    neutral: colors.neutral,
    success: colors.success,
    warning: colors.warning,
    surface1: colors.surface1,
    surface2: colors.surface2,
    surface3: colors.surface3,
    surface4: colors.surface4,
    surface5: colors.surface5,
    premium: colors.premium,
    divider: colors.neutral['400'],
    sys: colors.sys,
  },
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: customFontFamily,
      },
    },
    MuiIcon: {},
    MuiButton: {
      ...buttonStyles,
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          width: '90px',

          flexShrink: 0,
        },
        paper: {
          width: '90px',
          boxSizing: 'border-box',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {},
    },
  },
})
