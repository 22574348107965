import CssBaseline from '@mui/material/CssBaseline'
import { StyledEngineProvider } from '@mui/material/styles'
import { ThemeProvider } from '@mui/system'
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { theme } from 'config-mui'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { Session } from 'next-auth'
import { SessionProvider } from 'next-auth/react'
import { ReactElement, ReactNode, useEffect, useState } from 'react'
import { I18nextProvider } from 'react-i18next'

import { RefreshTokenHandler } from '@modules/Auth/components/RefreshTokenHandler'
import { DialogModal } from '@modules/Shared/MyDialogModal/components/DialogModal'
import { SnackbarAlert } from '@modules/Shared/SnackbarAlert/components/SnackbarAlert'

import i18n from '@src/i18n/config'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/700.css'

export type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps<{
  session: Session
  dehydratedState: unknown
}> & {
  Component: NextPageWithLayout
}

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page)
  const [queryClient] = useState(() => new QueryClient())
  const router = useRouter()

  const [interval, setInterval] = useState(0)

  useEffect(() => {
    if (router.pathname === '/_error') {
      void router.push('/installations')
    }
  }, [router])

  return (
    <SessionProvider session={pageProps.session} refetchInterval={interval}>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <I18nextProvider i18n={i18n}>
            <ThemeProvider theme={theme}>
              <StyledEngineProvider injectFirst>
                <CssBaseline />
                <SnackbarAlert />
                {getLayout(
                  <>
                    <DialogModal />
                    <Component {...pageProps} />
                  </>,
                )}
              </StyledEngineProvider>
            </ThemeProvider>
          </I18nextProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </Hydrate>
      </QueryClientProvider>
      <RefreshTokenHandler setInterval={setInterval} />
    </SessionProvider>
  )
}
