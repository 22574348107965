import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogTitle, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

import { globalStore } from '../../store/globalStore'

export const DialogModal = () => {
  const { closeDialog, dialogModal } = globalStore()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <div>
      <Dialog
        onClose={closeDialog}
        aria-labelledby="customized-dialog-title"
        open={dialogModal.open}
        fullScreen={fullScreen}
        fullWidth={true}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h5" fontWeight="regular">
              {dialogModal.titleDialog}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={closeDialog}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        {dialogModal.content}
      </Dialog>
    </div>
  )
}
